/** @jsx jsx */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
	useRef,
	useLayoutEffect,
	forwardRef,
	memo,
	type ReactElement,
	type MouseEventHandler,
	type MutableRefObject,
} from 'react';
import { css, keyframes, jsx } from '@compiled/react';

import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import { xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useInlineCreateHandlers,
	type TriggerPropsType,
} from '@atlassian/jira-issue-table-inline-issue-create/src/ui/index.tsx';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import {
	usePrevious,
	usePreviousWithInitial,
} from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { row_nativeIssueTable_fieldSets$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_fieldSets.graphql';
import type { row_nativeIssueTable_issue$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_issue.graphql';
import type { row_nativeIssueTable_RowInner_fieldSets$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_RowInner_fieldSets.graphql';
import type { row_nativeIssueTable_RowInner_issue$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_RowInner_issue.graphql';
import type { row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets.graphql';
import type { row_nativeIssueTable_MaybeRowWithDragAndDrop_issue$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_MaybeRowWithDragAndDrop_issue.graphql';
import type { row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge.graphql';
import type { row_nativeIssueTable_RowWithDragAndDrop_fieldSets$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_RowWithDragAndDrop_fieldSets.graphql';
import type { row_nativeIssueTable_RowWithDragAndDrop_issue$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_RowWithDragAndDrop_issue.graphql';
import type { row_nativeIssueTable_RowWithDragAndDrop_issueEdge$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_RowWithDragAndDrop_issueEdge.graphql';
import type { row_nativeIssueTable_RowWithSiblingCreate_fieldSets$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_RowWithSiblingCreate_fieldSets.graphql';
import type { row_nativeIssueTable_RowWithSiblingCreate_issue$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_RowWithSiblingCreate_issue.graphql';
import type { row_nativeIssueTable_RowInner_issueEdge$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_RowInner_issueEdge.graphql';
import type { row_nativeIssueTable_RowWithSiblingCreate_issueEdge$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_RowWithSiblingCreate_issueEdge.graphql';
import type { row_nativeIssueTable_issueEdge$key } from '@atlassian/jira-relay/src/__generated__/row_nativeIssueTable_issueEdge.graphql';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { getItemKey } from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import type { RowHeight } from '../../common/types.tsx';
import { useDraggableRow } from '../../controllers/draggable-rows/index.tsx';
import { useScrollStateSelector } from '../../controllers/scroll-state/index.tsx';
import {
	useIsIssueHierarchyEnabled,
	useIsEntireRowDraggable,
	useIsSiblingIssueCreateEnabled,
	useProjectContext,
	useIsColumnBordersEnabled,
	useIsDragAndDropEnabled,
	useIsReparentingEnabled,
} from '../../controllers/features/selectors.tsx';
import { useIsSelected, useIsFocused } from '../../controllers/hierarchy/index.tsx';
import { useIsIssueSelected } from '../../controllers/selected-issues/index.tsx';
import {
	useSiblingIssueCreateHandler,
	SiblingIssueCreateRowContext,
} from '../../controllers/sibling-issue-create-handler/index.tsx';
import { useObserveItem } from '../../controllers/table-items-intersection-observer/index.tsx';
import { useRowContext } from '../rows/row-context/index.tsx';
import { BeforeFirstCell } from './before-first-cell/index.tsx';
import BlankIssueRow from './blank-row/index.tsx';
import { IssueCreateRow } from './issue-create-row/index.tsx';
import { IssueRow } from './issue-row/index.tsx';
import type { RowProps } from './types.tsx';

type RowInnerProps = Omit<RowProps, 'issue' | 'issueEdge' | 'fieldSets'> & {
	issue: row_nativeIssueTable_RowInner_issue$key | null;
	issueEdge: row_nativeIssueTable_RowInner_issueEdge$key | null;
	fieldSets: row_nativeIssueTable_RowInner_fieldSets$key | null;
	onMouseLeave?: MouseEventHandler;
	onMouseMove?: MouseEventHandler;
	beforeRow?: ReactElement;
	createChildTriggerProps?: TriggerPropsType;
	isCreateChildFormVisible?: boolean;
	isScrollingRef?: MutableRefObject<boolean>;
	projectKey?: string | undefined | null;
};

const RowWithSiblingCreate = (
	props: Omit<RowProps, 'issue' | 'issueEdge' | 'fieldSets'> & {
		issue: row_nativeIssueTable_RowWithSiblingCreate_issue$key | null;
		issueEdge: row_nativeIssueTable_RowWithSiblingCreate_issueEdge$key | null;
		fieldSets: row_nativeIssueTable_RowWithSiblingCreate_fieldSets$key | null;
	},
) => {
	const { itemsConnectionId, rowIndex, parentIssueAri } = props;
	const issueRowDataOld = useFragment<row_nativeIssueTable_RowWithSiblingCreate_issue$key>(
		graphql`
			fragment row_nativeIssueTable_RowWithSiblingCreate_issue on JiraIssue
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
			) {
				...row_nativeIssueTable_MaybeRowWithDragAndDrop_issue
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
				id
			}
		`,
		props.issue,
	);
	const issueRowEdgeData = useFragment<row_nativeIssueTable_RowWithSiblingCreate_issueEdge$key>(
		graphql`
			fragment row_nativeIssueTable_RowWithSiblingCreate_issueEdge on JiraIssueEdge
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isReparentingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
			) {
				...row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isReparentingEnabled: $isReparentingEnabled
						isDensityFull: $isDensityFull
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
					)
				node {
					id
				}
			}
		`,
		props.issueEdge,
	);

	const issueRowData = fg('jsc_m2_hierarchy_fe_changes') ? issueRowEdgeData?.node : issueRowDataOld;

	const fieldSetsData = useFragment<row_nativeIssueTable_RowWithSiblingCreate_fieldSets$key>(
		graphql`
			fragment row_nativeIssueTable_RowWithSiblingCreate_fieldSets on JiraIssueFieldSetConnection
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
			) {
				...row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
			}
		`,
		props.fieldSets,
	);

	const { onMouseLeave, onMouseMove } = useSiblingIssueCreateHandler(itemsConnectionId, rowIndex);

	const projectContext = useProjectContext();
	const { isFormVisible, formProps, triggerProps } = useInlineCreateHandlers({
		projectKey: projectContext,
		minHierarchyLevel: 0,
	});

	const issueCreateRowContext = useMemo(
		() => ({ isFormVisible, triggerProps }),
		[isFormVisible, triggerProps],
	);

	const issueCreateRow = (
		<IssueCreateRow
			depth={0}
			isFormVisible={isFormVisible}
			issuesConnectionId={itemsConnectionId}
			// This will always be defined as we only render the sibling issue create button when issue data is present
			siblingIssueId={issueRowData?.id ?? ''}
			parentIssueAri={parentIssueAri}
			{...formProps}
		/>
	);

	return (
		<SiblingIssueCreateRowContext.Provider value={issueCreateRowContext}>
			<MaybeRowWithDragAndDrop
				{...props}
				issue={issueRowDataOld}
				issueEdge={issueRowEdgeData}
				fieldSets={fieldSetsData}
				onMouseLeave={onMouseLeave}
				onMouseMove={onMouseMove}
				beforeRow={issueCreateRow}
			/>
		</SiblingIssueCreateRowContext.Provider>
	);
};

const RowWithDragAndDrop = (
	props: Omit<RowInnerProps, 'issue' | 'issueEdge' | 'fieldSets'> & {
		issue: row_nativeIssueTable_RowWithDragAndDrop_issue$key | null;
		issueEdge: row_nativeIssueTable_RowWithDragAndDrop_issueEdge$key | null;
		fieldSets: row_nativeIssueTable_RowWithDragAndDrop_fieldSets$key | null;
	},
) => {
	const issueRowDataOld = useFragment<row_nativeIssueTable_RowWithDragAndDrop_issue$key>(
		graphql`
			fragment row_nativeIssueTable_RowWithDragAndDrop_issue on JiraIssue
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
			) {
				...row_nativeIssueTable_RowInner_issue
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
				id
			}
		`,
		props.issue,
	);

	const issueRowEdgeData = useFragment<row_nativeIssueTable_RowWithDragAndDrop_issueEdge$key>(
		graphql`
			fragment row_nativeIssueTable_RowWithDragAndDrop_issueEdge on JiraIssueEdge
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isReparentingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
			) {
				...row_nativeIssueTable_RowInner_issueEdge
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isReparentingEnabled: $isReparentingEnabled
						isDensityFull: $isDensityFull
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
					)
				__id
				hasChildren(filterByProjectKeys: $projectKeys)
					@include(if: $shouldQueryHasChildren)
					@optIn(to: "JiraSpreadsheetComponent-M2")
				node {
					id
				}
			}
		`,
		props.issueEdge,
	);

	const fieldSetsData = useFragment<row_nativeIssueTable_RowWithDragAndDrop_fieldSets$key>(
		graphql`
			fragment row_nativeIssueTable_RowWithDragAndDrop_fieldSets on JiraIssueFieldSetConnection
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
			) {
				...row_nativeIssueTable_RowInner_fieldSets
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
			}
		`,
		props.fieldSets,
	);

	const issueRowData = fg('jsc_m2_hierarchy_fe_changes') ? issueRowEdgeData?.node : issueRowDataOld;

	const draggableRef = useDraggableRow({
		edgeIndex: props.rowIndex,
		issueId: issueRowData?.id ?? '',
		hierarchyLevel: props.hierarchyLevel,
		parentIssueAri: props.parentIssueAri,
		parentIssueEdgeRelayId: props.parentItemConnectionId,
		parentIssueConnectionId: props.parentIssueRelayConnectionId,
		issueEdgeRelayId: issueRowEdgeData?.__id,
		depth: props.depth,
		itemsConnectionId: props.itemsConnectionId,
		projectKey: props.projectKey,
		hasChildren: Boolean(issueRowEdgeData?.hasChildren),
	});

	return (
		<MaybeMemoRowInner
			{...props}
			issue={issueRowDataOld}
			issueEdge={issueRowEdgeData}
			fieldSets={fieldSetsData}
			ref={draggableRef}
		/>
	);
};

const MaybeRowWithDragAndDrop = (
	props: Omit<RowInnerProps, 'issue' | 'issueEdge' | 'fieldSets'> & {
		issue: row_nativeIssueTable_MaybeRowWithDragAndDrop_issue$key | null;
		issueEdge: row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge$key | null;
		fieldSets: row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets$key | null;
	},
) => {
	const isDragAndDropEnabled = useIsDragAndDropEnabled();

	const issueRowDataOld = useFragment<row_nativeIssueTable_MaybeRowWithDragAndDrop_issue$key>(
		graphql`
			fragment row_nativeIssueTable_MaybeRowWithDragAndDrop_issue on JiraIssue
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
			) {
				...row_nativeIssueTable_RowInner_issue
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
				...row_nativeIssueTable_RowWithDragAndDrop_issue
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
				id
			}
		`,
		props.issue,
	);

	const issueRowEdgeData = useFragment<row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge$key>(
		graphql`
			fragment row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge on JiraIssueEdge
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isReparentingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
			) {
				...row_nativeIssueTable_RowInner_issueEdge
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isReparentingEnabled: $isReparentingEnabled
						isDensityFull: $isDensityFull
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
					)
				...row_nativeIssueTable_RowWithDragAndDrop_issueEdge
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isReparentingEnabled: $isReparentingEnabled
						isDensityFull: $isDensityFull
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
					)
				node {
					id
				}
			}
		`,
		props.issueEdge,
	);

	const fieldSetsData = useFragment<row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets$key>(
		graphql`
			fragment row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets on JiraIssueFieldSetConnection
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
			) {
				...row_nativeIssueTable_RowInner_fieldSets
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
				...row_nativeIssueTable_RowWithDragAndDrop_fieldSets
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
			}
		`,
		props.fieldSets,
	);

	if (isDragAndDropEnabled) {
		return (
			<RowWithDragAndDrop
				{...props}
				issue={issueRowDataOld}
				issueEdge={issueRowEdgeData}
				fieldSets={fieldSetsData}
			/>
		);
	}

	return (
		<MaybeMemoRowInner
			{...props}
			issue={issueRowDataOld}
			issueEdge={issueRowEdgeData}
			fieldSets={fieldSetsData}
		/>
	);
};

const RowInner = forwardRef<HTMLTableRowElement, RowInnerProps>((props, forwardedRef) => {
	const {
		rowIndex,
		issue,
		issueEdge,
		fieldSets,
		itemsConnectionId,
		depth,
		onMouseLeave,
		onMouseMove,
		beforeRow,
		triggerProps,
		isFormVisible,
		parentIssueAri,
		parentIssueRelayConnectionId,
		measureElement = noop,
		hierarchyLevel,
		issueKey,
		isScrollingRef,
		projectKey,
	} = props;

	const tableRowRef = useRef<HTMLTableRowElement>(null);
	const { groupId } = useRowContext();

	const issueRowDataOld = useFragment<row_nativeIssueTable_RowInner_issue$key>(
		graphql`
			fragment row_nativeIssueTable_RowInner_issue on JiraIssue
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
			) {
				...issueRow_nativeIssueTable_IssueRowWithFragments_issue
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
				id
				issueId
				isHighlightedIssueRow
			}
		`,
		issue,
	);

	const issueRowEdgeData = useFragment<row_nativeIssueTable_RowInner_issueEdge$key>(
		graphql`
			fragment row_nativeIssueTable_RowInner_issueEdge on JiraIssueEdge
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isReparentingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
			) {
				...issueRow_nativeIssueTable_IssueRowWithFragments_issueEdge
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isDensityFull: $isDensityFull
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
					)
				node {
					id
					issueId
					isHighlightedIssueRow
					...beforeFirstCell_nativeIssueTable
						@arguments(isReparentingEnabled: $isReparentingEnabled)
				}
			}
		`,
		issueEdge,
	);

	const issueRowData = fg('jsc_m2_hierarchy_fe_changes') ? issueRowEdgeData?.node : issueRowDataOld;

	const fieldSetsData = useFragment<row_nativeIssueTable_RowInner_fieldSets$key>(
		graphql`
			fragment row_nativeIssueTable_RowInner_fieldSets on JiraIssueFieldSetConnection
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
			) {
				...issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
			}
		`,
		fieldSets,
	);

	const itemDetails = useMemo(
		() => (issueRowData ? { itemsConnectionId, itemId: issueRowData.issueId, groupId } : null),
		[issueRowData, itemsConnectionId, groupId],
	);

	const [isSelected, { setSelectedRow }] = useIsSelected(itemDetails, {
		selectedRowIndex: rowIndex,
	});
	const prevIsSelected = usePreviousWithInitial(isSelected);
	const [isFocused, { resetFocus }] = useIsFocused(itemDetails, { selectedRowIndex: rowIndex });

	// TODO: remove when cleaning jsc_list_reparenting
	const [isRowHoveredOrSelected, setIsRowHoveredOrSelected] = useState(isFocused || isSelected);

	let setIsRowHovered = noop;
	let isRowHovered = false;
	let isDragAndDropEnabled = false;
	let isReParentingEnabled = false;
	let isScrolling = false;
	if (fg('jsc_list_reparenting') || fg('jira_native_issue_table_row_drag_and_drop')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[isRowHovered, setIsRowHovered] = useState(false);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isDragAndDropEnabled = useIsDragAndDropEnabled();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isReParentingEnabled = useIsReparentingEnabled();
	}

	const [currentDraggingRow, setCurrentDraggingRow] = useState<string | null>(null);

	if (fg('empanada_nin_concurrent_mode_fixes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isScrolling = useScrollStateSelector((scrollState) => scrollState.isScrolling);
	}

	const isEntireRowDraggable = useIsEntireRowDraggable();

	const isIssueSelected = expVal('bulk_operations_in_nin_experiment', 'isEnabled', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsIssueSelected(issueRowData?.issueId ?? '')
		: false;

	useEffect(() => {
		// Explicitly reset our internal state to track which row is focused. We do this to prevent the DOM element being re-focused unintentionally when the component is re-rendered.
		if (isFocused) {
			resetFocus();
		}
	}, [isFocused, resetFocus]);

	useEffect(() => {
		// TODO: remove when cleaning jsc_list_reparenting
		setIsRowHoveredOrSelected(isSelected || isFocused);
	}, [isFocused, isSelected]);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	let isIssueHierarchyEnabled = false;
	if (fg('jsc_m2_hierarchy_fe_changes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isIssueHierarchyEnabled = useIsIssueHierarchyEnabled();
	}

	const onMouseMoveHandler = useCallback(
		(e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
			onMouseMove?.(e);
			if (fg('jsc_list_reparenting') || fg('jira_native_issue_table_row_drag_and_drop')) {
				return;
			}

			const isUserScrolling = fg('empanada_nin_concurrent_mode_fixes')
				? isScrolling
				: isScrollingRef?.current;

			if (isIssueHierarchyEnabled && !isUserScrolling && fg('jsc_m2_hierarchy_fe_changes')) {
				setIsRowHoveredOrSelected(true);
			}
		},
		[isIssueHierarchyEnabled, isScrolling, isScrollingRef, onMouseMove],
	);

	const onMouseOverHandler = useCallback(() => {
		if (fg('jsc_list_reparenting') || fg('jira_native_issue_table_row_drag_and_drop')) {
			const isUserScrolling = fg('empanada_nin_concurrent_mode_fixes')
				? isScrolling
				: isScrollingRef?.current;

			if (
				!isUserScrolling &&
				((isIssueHierarchyEnabled && fg('jsc_m2_hierarchy_fe_changes')) || isDragAndDropEnabled)
			) {
				setIsRowHovered(true);
			}
		}
	}, [isDragAndDropEnabled, isIssueHierarchyEnabled, isScrolling, isScrollingRef, setIsRowHovered]);

	const onMouseLeaveHandler = useCallback(
		(e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
			onMouseLeave?.(e);
			if ((isIssueHierarchyEnabled && fg('jsc_m2_hierarchy_fe_changes')) || isDragAndDropEnabled) {
				const isUserScrolling = fg('empanada_nin_concurrent_mode_fixes')
					? isScrolling
					: isScrollingRef?.current;
				if (fg('jsc_list_reparenting') || fg('jira_native_issue_table_row_drag_and_drop')) {
					setIsRowHovered(false);
				} else if (!isUserScrolling) {
					if (!isFocused && !isSelected) {
						setIsRowHoveredOrSelected(false);
					}
				}
			}
		},
		[
			isDragAndDropEnabled,
			isFocused,
			isIssueHierarchyEnabled,
			isScrolling,
			isScrollingRef,
			isSelected,
			onMouseLeave,
			setIsRowHovered,
		],
	);

	const focusCurrentRow = useCallback(() => {
		return setSelectedRow(
			// The method below has a different signature compared to the old implementation which leads to this type error which is not trivial to solve
			// @ts-expect-error - TS2345: Argument of type '[number | { itemsConnectionId: string; itemId: string; }]' is not assignable to parameter of type '{ itemsConnectionId: string; itemId: string; } & number'
			fg('jsc_m2_hierarchy_fe_changes') ? itemDetails : rowIndex,
		);
	}, [setSelectedRow, itemDetails, rowIndex]);

	const onClick = useCallback(() => {
		const clickAnalytics = createAnalyticsEvent({});
		const actionSubjectId = issueRowData ? 'issueRow' : 'blankRow';
		fireUIAnalytics(clickAnalytics, 'rowItem clicked', actionSubjectId);
	}, [createAnalyticsEvent, issueRowData]);

	const isColumnBordersEnabled = useIsColumnBordersEnabled();
	const isColumnBordersEnabledFinal = fg('jiv-19793-remove-column-lines')
		? isColumnBordersEnabled
		: true;

	const cellBorder = useMemo(
		() => [
			isColumnBordersEnabledFinal ? cellBorderStyles : cellBorderStylesWithNoColumn,
			rowIndex === 0 && !parentIssueAri && firstRowCellBorderStyles,
		],
		[parentIssueAri, rowIndex, isColumnBordersEnabledFinal],
	);

	// when cleaning up jsc_m2_hierarchy_fe_changes, replace issueRowDataOld with issueRowEdgeData, and ensure it is a required property
	const rowContent = issueRowDataOld ? (
		<IssueRow
			issue={issueRowDataOld}
			issueEdge={issueRowEdgeData}
			fieldSets={fieldSetsData}
			cellBorder={cellBorder}
			beforeFirstCell={
				<BeforeFirstCell
					edgeIndex={rowIndex}
					issueId={issueRowDataOld.id}
					itemsConnectionId={itemsConnectionId}
					hierarchyLevel={hierarchyLevel}
					parentIssueAri={parentIssueAri}
					parentIssueConnectionId={parentIssueRelayConnectionId}
					issueKey={issueKey}
					projectKey={projectKey}
					{...(isDragAndDropEnabled ? { isRowHovered } : {})}
					{...(isReParentingEnabled
						? {
								issueRowData: issueRowEdgeData?.node || null,
								setCurrentDraggingRow,
							}
						: { issueRowData: null })}
				/>
			}
			itemsConnectionId={itemsConnectionId}
			depth={depth}
			{...(fg('jsc_m2_hierarchy_fe_changes') && {
				isRowHoveredOrSelected,
			})}
			{...((fg('jsc_list_reparenting') || fg('jira_native_issue_table_row_drag_and_drop')) && {
				isRowHoveredOrSelected: isFocused || isSelected || isRowHovered,
			})}
			{...((expVal('bulk_operations_in_nin_experiment', 'isEnabled', false) ||
				fg('jira_list_grid_pattern_keyboard_nav')) && {
				rowIndex,
			})}
			triggerProps={triggerProps}
			isFormVisible={isFormVisible}
		/>
	) : (
		<BlankIssueRow isSelected={isSelected} cellBorder={cellBorder} />
	);

	useLayoutEffect(() => {
		// Handled by a separate layout effect below
		if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) return;
		// Scroll the table row into view (if needed - block: nearest) when it becomes selected
		if (isSelected) {
			tableRowRef.current?.scrollIntoView({ block: 'nearest' });
		}
	}, [isSelected]);

	/**
	 * Only scroll table row into view when it's a _newly_ selected row.
	 *
	 * We keep track of the prev selected state to avoid jumping to the selected row on mount. This issue
	 * was introduced when we enabled virtualization as part of the infinite scroll work.
	 * @see https://getsupport.atlassian.com/browse/PCS-359806
	 */
	useLayoutEffect(() => {
		if (!expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) return;

		if (isSelected && isSelected !== prevIsSelected) {
			tableRowRef.current?.scrollIntoView({ block: 'nearest' });
		}
	}, [isSelected, prevIsSelected]);

	const highlightFadeOut = useRef(false);
	const onInterceptionItemRef = useObserveItem<HTMLTableRowElement>(issueRowData?.id);

	let trRef = useMergeRefs(
		onInterceptionItemRef,
		tableRowRef,
		fg('jsc_list_reparenting') || fg('jira_native_issue_table_row_drag_and_drop')
			? forwardedRef
			: null,
	);

	const isHighlighted = issueRowData?.isHighlightedIssueRow ?? false;
	const prevIsHighlighted = usePrevious(isHighlighted);
	// Begin fade out animation when isHighlighted changes to false
	if (prevIsHighlighted && !isHighlighted) {
		highlightFadeOut.current = true;
	}

	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		trRef = useMergeRefs(trRef, measureElement);
	}

	let itemKey = null;
	if (itemsConnectionId && issueRowData?.id) {
		itemKey = getItemKey(itemsConnectionId, issueRowData.id);
	}

	return (
		<>
			{beforeRow}
			<tr
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				data-testid="native-issue-table.ui.issue-row"
				aria-selected={isSelected}
				role="row"
				onAnimationEnd={() => {
					/*
						Animation set using @compiled may be inadvertently started when the scripting engine applies
						new styles (e.g. after a new issue row is appended). We need to set the animation duration
						to 0s once the animation has completed to prevent the animation restarting.
						See https://drafts.csswg.org/css-animations-1/#animations
						 */
					if (tableRowRef.current) {
						tableRowRef.current.style.animationDuration = '0s';
					}
				}}
				onFocus={focusCurrentRow}
				onClick={onClick}
				onMouseOver={onMouseOverHandler}
				onMouseMove={onMouseMoveHandler}
				onMouseLeave={onMouseLeaveHandler}
				tabIndex={-1}
				{...(expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) && {
					'data-index': rowIndex,
				})}
				ref={trRef}
				css={[
					rowStyles,
					highlightFadeOut.current && highlightFadeOutStyles,
					// TODO This can be removed once we introduce cell-based navigation in M3 inline editing
					// remove line with activeRowStylesOld when cleaning bulk_operations_in_nin
					isSelected &&
						!expVal('bulk_operations_in_nin_experiment', 'isEnabled', false) &&
						activeRowStylesOld,
					(isSelected || isIssueSelected) &&
						expVal('bulk_operations_in_nin_experiment', 'isEnabled', false) &&
						activeRowStyles,
					isHighlighted && highlightRowStyles,
					isEntireRowDraggable && draggableRowStyles,
					currentDraggingRow === itemKey && fg('jsc_list_reparenting') && draggingRowStyles,
				]}
				data-vc={`issue-row${__SERVER__ ? '-ssr' : ''}`}
				{...(__SERVER__ &&
					fg('additional_nin_ssr_placeholders') && {
						'data-ssr-placeholder': 'issue-row-placeholder',
					})}
				{...(!__SERVER__ &&
					fg('additional_nin_ssr_placeholders') && {
						'data-ssr-placeholder-replace': 'issue-row-placeholder',
					})}
			>
				{rowContent}
			</tr>
		</>
	);
});

const MaybeMemoRowInner = componentWithFG('jsc_virtualizer_rss_store', memo(RowInner), RowInner);

const Row = (props: RowProps): JSX.Element => {
	const issueRowData = useFragment<row_nativeIssueTable_issue$key>(
		graphql`
			fragment row_nativeIssueTable_issue on JiraIssue
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
			) {
				id
				...row_nativeIssueTable_RowInner_issue
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
				...row_nativeIssueTable_RowWithSiblingCreate_issue
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
				...row_nativeIssueTable_MaybeRowWithDragAndDrop_issue
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
				...row_nativeIssueTable_RowWithDragAndDrop_issue
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
			}
		`,
		props.issue,
	);

	const issueRowEdgeData = useFragment<row_nativeIssueTable_issueEdge$key>(
		graphql`
			fragment row_nativeIssueTable_issueEdge on JiraIssueEdge
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isReparentingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
			) {
				...row_nativeIssueTable_RowInner_issueEdge
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isDensityFull: $isDensityFull
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
						isReparentingEnabled: $isReparentingEnabled
					)
				...row_nativeIssueTable_RowWithSiblingCreate_issueEdge
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isReparentingEnabled: $isReparentingEnabled
						isDensityFull: $isDensityFull
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
					)
				...row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isReparentingEnabled: $isReparentingEnabled
						isDensityFull: $isDensityFull
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
					)
				...row_nativeIssueTable_RowWithDragAndDrop_issueEdge
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isReparentingEnabled: $isReparentingEnabled
						isDensityFull: $isDensityFull
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
					)
				node {
					id
				}
			}
		`,
		props.issueEdge,
	);

	const fieldSetsData = useFragment<row_nativeIssueTable_fieldSets$key>(
		graphql`
			fragment row_nativeIssueTable_fieldSets on JiraIssueFieldSetConnection
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
			) {
				...row_nativeIssueTable_RowInner_fieldSets
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
				...row_nativeIssueTable_RowWithSiblingCreate_fieldSets
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
				...row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
				...row_nativeIssueTable_RowWithDragAndDrop_fieldSets
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
			}
		`,
		props.fieldSets,
	);

	const isSiblingIssueCreateEnabled = useIsSiblingIssueCreateEnabled();

	if (isSiblingIssueCreateEnabled) {
		return (
			<RowWithSiblingCreate
				{...props}
				issue={issueRowData}
				issueEdge={issueRowEdgeData}
				fieldSets={fieldSetsData}
			/>
		);
	}

	return (
		<MaybeRowWithDragAndDrop
			{...props}
			issue={issueRowData}
			issueEdge={issueRowEdgeData}
			fieldSets={fieldSetsData}
		/>
	);
};

export default Row;

const ROW_HEIGHT: RowHeight = 40;

const cellBorderStyles = xcss({
	borderColor: 'color.border',
	borderStyle: 'solid',
	borderTopWidth: 'border.width',
	borderBottomWidth: '0',
	borderLeftWidth: '0',
	borderRightWidth: 'border.width',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':last-of-type': {
		borderRightWidth: '0',
	},
});

const cellBorderStylesWithNoColumn = xcss({
	borderColor: 'color.border',
	borderStyle: 'solid',
	borderTopWidth: 'border.width',
	borderBottomWidth: '0',
	borderLeftWidth: '0',
	borderRightWidth: '0',
});

const firstRowCellBorderStyles = xcss({
	borderTopWidth: '0',
});

const fadeOut = keyframes({
	from: {
		backgroundColor: token('color.background.accent.blue.subtlest'),
	},
	to: {
		backgroundColor: token('elevation.surface'),
	},
});

const rowStyles = css({
	height: `${ROW_HEIGHT}px`,
	position: 'relative',
	'&:focus': {
		outline: 'none',
	},
	backgroundColor: token('elevation.surface'),
	'&:hover': {
		animationDuration: '0s', // Skip highlight fade out animation when the row is hovered
		backgroundColor: token('elevation.surface.hovered'),
	},
});

const draggableRowStyles = css({
	cursor: 'grab',
	'&:active': {
		cursor: 'grabbing',
	},
});

const activeRowStylesOld = css({
	animationDuration: '0s', // Skip highlight fade out animation when the row is selected
	backgroundColor: token('color.background.selected'),
	'&:hover': {
		backgroundColor: token('color.background.selected'),
	},
});

const activeRowStyles = css({
	animationDuration: '0s', // Skip highlight fade out animation when the row is selected
	backgroundColor: token('color.background.selected'),
	'&:hover': {
		backgroundColor: token('color.background.selected.hovered'),
	},
});

const highlightRowStyles = css({
	backgroundColor: token('color.background.accent.blue.subtlest'),
});

const draggingRowStyles = css({
	opacity: '0.4',
});

const highlightFadeOutStyles = css({
	animationName: `${fadeOut}`,
	animationDuration: '2s',
	animationTimingFunction: 'ease-out',
});
