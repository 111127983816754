import { useCallback } from 'react';
import { commitLocalUpdate } from 'react-relay';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type {
	JiraIssueExpandedGroup,
	JiraIssueExpandedParent,
} from '@atlassian/jira-relay/src/__generated__/realtimeIssueDetails_Query.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	useConnectionsList,
	type ConnectionDetails,
} from '@atlassian/jira-issue-table-hierarchy/src/controllers/connections-list/index.tsx';
import {
	useIsDensityFull,
	useIsInlineEditingEnabled,
} from '../../controllers/features/selectors.tsx';
import { useIssueFetchQueryVariables } from '../../controllers/issue-fetch-query-variables/index.tsx';
import { useIssueRealtimeUpdater } from '../../controllers/issue-realtime-updater/index.tsx';
import { useGetRealtimeIssueDetails } from '../../services/realtime-issue-details/index.tsx';
import type { SearchViewContextInputType } from '../../services/realtime-issue-details/types.tsx';
import { useGetGroupsForIssues } from '../../services/groups-for-issues/index.tsx';

export const generateSearchViewContextInput = ({
	connections,
	isGroupingEnabled,
	groupedByFieldId,
}: {
	connections: Record<string, ConnectionDetails>;
	isGroupingEnabled: boolean;
	groupedByFieldId?: string | null;
}): SearchViewContextInputType => {
	const expandedParents: JiraIssueExpandedParent[] = [];
	const groups: JiraIssueExpandedGroup[] = [];

	Object.values(connections).forEach((connection) => {
		if (connection.type === 'PARENT_CHILDREN') {
			expandedParents.push({ parentIssueId: connection.parentId, first: connection.first });
		} else if (connection.type === 'GROUP_CHILDREN') {
			groups.push({
				jql: connection.jql,
				fieldValue: connection.fieldValue,
				first: connection.first,
			});
		}
	});

	if (isGroupingEnabled && groupedByFieldId) {
		return { expandedGroups: { groupedByFieldId, groups } };
	}

	return { expandedParents };
};

export const useRealtimeUpdatesForIssues = () => {
	const environment = getRelayEnvironment();
	const cloudId = useCloudId();
	const { fieldSetIds } = useIssueFetchQueryVariables();
	const isDensityFull = useIsDensityFull();
	const isInlineEditingEnabled = useIsInlineEditingEnabled();
	const { connections } = useConnectionsList();
	const getGroupsForIssues = useGetGroupsForIssues();

	const getRealtimeIssueDetails = useGetRealtimeIssueDetails();
	const { issuePositionUpdater, groupsForIssueUpdater } = useIssueRealtimeUpdater();

	return useCallback(
		async ({
			keys,
			jql,
			groupByFieldId,
			numGroupsLoaded,
			groupConnectionId,
			isHierarchyEnabled,
			projectKey,
		}: {
			keys: string[];
			jql: string;
			groupByFieldId: string | undefined;
			groupConnectionId: string | undefined;
			numGroupsLoaded: number;
			isHierarchyEnabled: boolean;
			projectKey: string;
		}) => {
			const isGroupingEnabled = !!groupByFieldId;
			const searchViewContextInput = generateSearchViewContextInput({
				connections,
				isGroupingEnabled,
				groupedByFieldId: groupByFieldId,
			});

			const issueDetails = await getRealtimeIssueDetails({
				keys,
				cloudId,
				fieldSetIds,
				isInlineEditingEnabled,
				isDensityFull,
				isHierarchyEnabled,
				isGroupingEnabled,
				issueSearchInput: {
					jql,
				},
				searchViewContextInput,
				projectKey,
			});

			issueDetails?.jira?.issuesByKey?.filter(Boolean).forEach(async (issue) => {
				const searchViewContext = issue.searchViewContext;
				const id = issue.issueId;
				const issueRecordId = issue.__id;
				const parentIssueNodeRecordId = issue.parentIssueField?.parentIssue?.__id;

				const fieldSetConnectionId = issue?.fieldSetsById?.__id;
				if (id && issueRecordId) {
					commitLocalUpdate(environment, (store) => {
						issuePositionUpdater({
							store,
							searchViewContext,
							fieldSetConnectionId,
							issueId: id,
							issueRecordId,
							parentIssueNodeRecordId,
							groupConnectionId,
							projectKey,
						});
					});
				}
			});

			if (!isGroupingEnabled || !groupByFieldId) {
				return;
			}

			const issueKeysWithoutContext =
				issueDetails?.jira?.issuesByKey
					?.map((issue) =>
						!issue?.searchViewContext?.contexts?.length && !!issue?.key ? issue.key : null,
					)
					.filter(Boolean) || [];

			const groupsToInsert = await getGroupsForIssues({
				issueKeys: issueKeysWithoutContext,
				fieldId: groupByFieldId,
				jql,
				numGroupsLoaded,
			});

			if (groupsToInsert.length > 0) {
				commitLocalUpdate(environment, (store) => {
					groupsForIssueUpdater({
						store,
						groupConnectionId,
						groupsForIssue: groupsToInsert,
					});
				});
			}
		},
		[
			connections,
			cloudId,
			fieldSetIds,
			getRealtimeIssueDetails,
			isInlineEditingEnabled,
			isDensityFull,
			environment,
			issuePositionUpdater,
			getGroupsForIssues,
			groupsForIssueUpdater,
		],
	);
};
