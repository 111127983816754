/**
 * @generated SignedSource<<fde0e523b0ce7c51fda2eae443b7fdab>>
 * @relayHash c03991e1038aa2aa944392df5bc97683
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 43e25a80d5826b6301709f35dd671e91bc63841e44f07ac54b8137ae0610d4b5

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  namespace?: string | null | undefined;
  parentIssueKey: string;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchViewContextInput = {
  expandedGroups?: JiraIssueExpandedGroups | null | undefined;
  expandedParents?: ReadonlyArray<JiraIssueExpandedParent> | null | undefined;
};
export type JiraIssueExpandedGroups = {
  groupedByFieldId: string;
  groups?: ReadonlyArray<JiraIssueExpandedGroup> | null | undefined;
};
export type JiraIssueExpandedGroup = {
  fieldValue?: string | null | undefined;
  first?: number | null | undefined;
  jql: string;
};
export type JiraIssueExpandedParent = {
  first?: number | null | undefined;
  parentIssueId: string;
};
export type realtimeIssueDetails_Query$variables = {
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  isDensityFull: boolean;
  isGroupingEnabled?: boolean | null | undefined;
  isHierarchyEnabled: boolean;
  isInlineEditingEnabled: boolean;
  issueSearchInput: JiraIssueSearchInput;
  keys: ReadonlyArray<string>;
  projectKey: string;
  searchViewContextInput: JiraIssueSearchViewContextInput;
};
export type realtimeIssueDetails_Query$data = {
  readonly jira: {
    readonly issuesByKey: ReadonlyArray<{
      readonly __id: string;
      readonly canHaveChildIssues?: boolean | null | undefined;
      readonly fieldSetsById: {
        readonly __id: string;
        readonly " $fragmentSpreads": FragmentRefs<"issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets">;
      } | null | undefined;
      readonly issueId: string;
      readonly issueTypeField?: {
        readonly id: string;
        readonly issueType: {
          readonly hierarchy: {
            readonly level: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly key: string;
      readonly parentIssueField: {
        readonly parentIssue: {
          readonly __id: string;
        } | null | undefined;
      } | null | undefined;
      readonly searchViewContext: {
        readonly contexts: ReadonlyArray<{
          readonly afterIssueId: string | null | undefined;
          readonly beforeIssueId: string | null | undefined;
          readonly jql?: string | null | undefined;
          readonly parentIssueId?: string | null | undefined;
          readonly position: number | null | undefined;
        }> | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type realtimeIssueDetails_Query = {
  response: realtimeIssueDetails_Query$data;
  variables: realtimeIssueDetails_Query$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider: {
    readonly get: () => boolean;
  };
});

import isJscInlineEditRefactorEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';
import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDensityFull"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isGroupingEnabled"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isHierarchyEnabled"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isInlineEditingEnabled"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "keys"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchViewContextInput"
},
v10 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "keys",
    "variableName": "keys"
  }
],
v11 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v12 = {
  "kind": "ScalarField",
  "name": "key"
},
v13 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v14 = {
  "kind": "ScalarField",
  "name": "id"
},
v15 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "level"
    }
  ]
},
v16 = {
  "args": [
    {
      "kind": "Variable",
      "name": "projectKey",
      "variableName": "projectKey"
    }
  ],
  "kind": "ScalarField",
  "name": "canHaveChildIssues"
},
v17 = [
  {
    "kind": "Variable",
    "name": "fieldSetIds",
    "variableName": "fieldSetIds"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 500
  }
],
v18 = [
  {
    "kind": "Variable",
    "name": "isGroupingEnabled",
    "variableName": "isGroupingEnabled"
  },
  {
    "kind": "Variable",
    "name": "isHierarchyEnabled",
    "variableName": "isHierarchyEnabled"
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  },
  {
    "kind": "Variable",
    "name": "searchViewContextInput",
    "variableName": "searchViewContextInput"
  }
],
v19 = {
  "kind": "ScalarField",
  "name": "afterIssueId"
},
v20 = {
  "kind": "ScalarField",
  "name": "beforeIssueId"
},
v21 = {
  "kind": "ScalarField",
  "name": "position"
},
v22 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "parentIssueId"
    }
  ],
  "type": "JiraIssueSearchViewContextMappingByParent"
},
v23 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "jql"
    }
  ],
  "type": "JiraIssueSearchViewContextMappingByGroup"
},
v24 = {
  "kind": "ScalarField",
  "name": "type"
},
v25 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v26 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v27 = {
  "kind": "ScalarField",
  "name": "name"
},
v28 = {
  "kind": "ScalarField",
  "name": "value"
},
v29 = [
  (v28/*: any*/),
  (v14/*: any*/),
  {
    "kind": "ScalarField",
    "name": "isDisabled"
  }
],
v30 = {
  "condition": "isInlineEditingEnabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "lazyIsEditableInIssueView"
        }
      ]
    }
  ]
},
v31 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v32 = {
  "alias": "value",
  "kind": "ScalarField",
  "name": "id"
},
v33 = {
  "alias": "label",
  "kind": "ScalarField",
  "name": "name"
},
v34 = {
  "kind": "ScalarField",
  "name": "dateTime"
},
v35 = {
  "kind": "ScalarField",
  "name": "text"
},
v36 = {
  "kind": "InlineFragment",
  "selections": [
    (v35/*: any*/),
    (v27/*: any*/),
    (v30/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v37 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v38 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "colorKey"
    },
    (v14/*: any*/)
  ]
},
v39 = [
  (v38/*: any*/)
],
v40 = {
  "kind": "ScalarField",
  "name": "optionId"
},
v41 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v42 = {
  "kind": "ScalarField",
  "name": "picture"
},
v43 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v44 = [
  (v27/*: any*/),
  (v14/*: any*/)
],
v45 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v46 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v47 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v46/*: any*/),
    (v14/*: any*/)
  ]
},
v48 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    (v35/*: any*/),
    (v14/*: any*/)
  ]
},
v49 = {
  "concreteType": "JiraColorField",
  "kind": "LinkedField",
  "name": "issueColorField",
  "plural": false,
  "selections": [
    (v38/*: any*/),
    (v14/*: any*/)
  ]
},
v50 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v51 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v50/*: any*/),
        (v27/*: any*/),
        (v14/*: any*/)
      ]
    },
    (v14/*: any*/)
  ]
},
v52 = {
  "kind": "ScalarField",
  "name": "webUrl"
},
v53 = {
  "kind": "ScalarField",
  "name": "number"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "name": "realtimeIssueDetails_Query",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v10/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issuesByKey",
            "plural": true,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "concreteType": "JiraParentIssueField",
                "kind": "LinkedField",
                "name": "parentIssueField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "parentIssue",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "isHierarchyEnabled",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueTypeField",
                    "kind": "LinkedField",
                    "name": "issueTypeField",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      {
                        "concreteType": "JiraIssueType",
                        "kind": "LinkedField",
                        "name": "issueType",
                        "plural": false,
                        "selections": [
                          (v15/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v16/*: any*/)
                ]
              },
              {
                "args": (v17/*: any*/),
                "concreteType": "JiraIssueFieldSetConnection",
                "kind": "LinkedField",
                "name": "fieldSetsById",
                "plural": false,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "isDensityFull",
                        "variableName": "isDensityFull"
                      },
                      {
                        "kind": "Variable",
                        "name": "isInlineEditingEnabled",
                        "variableName": "isInlineEditingEnabled"
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets"
                  },
                  (v13/*: any*/)
                ]
              },
              {
                "args": (v18/*: any*/),
                "concreteType": "JiraIssueSearchViewContexts",
                "kind": "LinkedField",
                "name": "searchViewContext",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "contexts",
                    "plural": true,
                    "selections": [
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/)
                    ]
                  }
                ]
              },
              (v13/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v9/*: any*/),
      (v8/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "realtimeIssueDetails_Query",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v10/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issuesByKey",
            "plural": true,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "concreteType": "JiraParentIssueField",
                "kind": "LinkedField",
                "name": "parentIssueField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "parentIssue",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v13/*: any*/)
                    ]
                  },
                  (v14/*: any*/)
                ]
              },
              {
                "args": (v17/*: any*/),
                "concreteType": "JiraIssueFieldSetConnection",
                "kind": "LinkedField",
                "name": "fieldSetsById",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldSetEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueFieldSet",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v24/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "fieldSetId"
                          },
                          {
                            "concreteType": "JiraIssueFieldConnection",
                            "kind": "LinkedField",
                            "name": "fields",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v25/*: any*/),
                                      (v26/*: any*/),
                                      (v24/*: any*/),
                                      {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isJiraIssueField"
                                      },
                                      (v14/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraAffectedServiceConnection",
                                            "kind": "LinkedField",
                                            "name": "selectedAffectedServicesConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraAffectedServiceEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraAffectedService",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "serviceId"
                                                      },
                                                      (v27/*: any*/),
                                                      (v14/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ],
                                        "type": "JiraAffectedServicesField"
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v27/*: any*/),
                                              {
                                                "concreteType": "JiraCascadingOption",
                                                "kind": "LinkedField",
                                                "name": "cascadingOption",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraOption",
                                                    "kind": "LinkedField",
                                                    "name": "parentOptionValue",
                                                    "plural": false,
                                                    "selections": (v29/*: any*/)
                                                  },
                                                  {
                                                    "concreteType": "JiraOption",
                                                    "kind": "LinkedField",
                                                    "name": "childOptionValue",
                                                    "plural": false,
                                                    "selections": (v29/*: any*/)
                                                  }
                                                ]
                                              },
                                              (v30/*: any*/)
                                            ],
                                            "type": "JiraCascadingSelectField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v27/*: any*/),
                                              {
                                                "args": (v31/*: any*/),
                                                "concreteType": "JiraComponentConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedComponentsConnection",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraComponentEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraComponent",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v32/*: any*/),
                                                          (v33/*: any*/),
                                                          (v14/*: any*/)
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "storageKey": "selectedComponentsConnection(first:1000)"
                                              },
                                              (v30/*: any*/)
                                            ],
                                            "type": "JiraComponentsField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v34/*: any*/),
                                              (v27/*: any*/),
                                              (v30/*: any*/)
                                            ],
                                            "type": "JiraDateTimePickerField"
                                          },
                                          (v36/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraCommentSummary",
                                                "kind": "LinkedField",
                                                "name": "commentSummary",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "canAddComment"
                                                  },
                                                  (v37/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraCommentSummaryField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v27/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "uri"
                                              },
                                              (v30/*: any*/)
                                            ],
                                            "type": "JiraUrlField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v27/*: any*/),
                                              {
                                                "concreteType": "JiraOptionConnection",
                                                "kind": "LinkedField",
                                                "name": "fieldOptions",
                                                "plural": false,
                                                "selections": [
                                                  (v37/*: any*/)
                                                ]
                                              },
                                              {
                                                "concreteType": "JiraOption",
                                                "kind": "LinkedField",
                                                "name": "fieldOption",
                                                "plural": false,
                                                "selections": [
                                                  (v28/*: any*/),
                                                  {
                                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                                    "kind": "Condition",
                                                    "passingValue": true,
                                                    "selections": (v39/*: any*/)
                                                  },
                                                  {
                                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider",
                                                    "kind": "Condition",
                                                    "passingValue": true,
                                                    "selections": (v39/*: any*/)
                                                  },
                                                  (v14/*: any*/),
                                                  (v40/*: any*/)
                                                ]
                                              },
                                              (v30/*: any*/)
                                            ],
                                            "type": "JiraSingleSelectField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v27/*: any*/),
                                              {
                                                "concreteType": "JiraOptionConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedOptions",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraOptionEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraOption",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v14/*: any*/),
                                                          (v28/*: any*/),
                                                          (v40/*: any*/)
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              },
                                              (v30/*: any*/)
                                            ],
                                            "type": "JiraMultipleSelectField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v27/*: any*/),
                                              {
                                                "concreteType": "JiraOptionConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedOptions",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraOptionEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraOption",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v14/*: any*/),
                                                          (v28/*: any*/)
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              },
                                              (v30/*: any*/)
                                            ],
                                            "type": "JiraCheckboxesField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v27/*: any*/),
                                              {
                                                "concreteType": "JiraUserConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedUsersConnection",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraUserEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v25/*: any*/),
                                                          (v41/*: any*/),
                                                          (v27/*: any*/),
                                                          (v42/*: any*/),
                                                          (v14/*: any*/),
                                                          (v43/*: any*/),
                                                          {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "email"
                                                              }
                                                            ],
                                                            "type": "AtlassianAccountUser"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              },
                                              (v30/*: any*/)
                                            ],
                                            "type": "JiraMultipleSelectUserPickerField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v27/*: any*/),
                                              {
                                                "concreteType": "JiraTeamView",
                                                "kind": "LinkedField",
                                                "name": "selectedTeam",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "jiraSuppliedId"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "jiraSuppliedName"
                                                  }
                                                ]
                                              },
                                              (v30/*: any*/)
                                            ],
                                            "type": "JiraTeamViewField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "issue",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraTimeTrackingField",
                                                    "kind": "LinkedField",
                                                    "name": "timeTrackingField",
                                                    "plural": false,
                                                    "selections": [
                                                      (v14/*: any*/),
                                                      (v30/*: any*/),
                                                      {
                                                        "concreteType": "JiraEstimate",
                                                        "kind": "LinkedField",
                                                        "name": "originalEstimate",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "timeInSeconds"
                                                          }
                                                        ]
                                                      },
                                                      {
                                                        "concreteType": "JiraTimeTrackingSettings",
                                                        "kind": "LinkedField",
                                                        "name": "timeTrackingSettings",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "workingHoursPerDay"
                                                          },
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "workingDaysPerWeek"
                                                          },
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "defaultFormat"
                                                          },
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "defaultUnit"
                                                          }
                                                        ]
                                                      },
                                                      (v26/*: any*/),
                                                      (v27/*: any*/),
                                                      (v24/*: any*/)
                                                    ]
                                                  },
                                                  (v14/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraOriginalTimeEstimateField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v27/*: any*/),
                                              {
                                                "concreteType": "JiraOption",
                                                "kind": "LinkedField",
                                                "name": "selectedOption",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "alias": "label",
                                                    "kind": "ScalarField",
                                                    "name": "value"
                                                  },
                                                  (v14/*: any*/),
                                                  (v32/*: any*/)
                                                ]
                                              },
                                              (v30/*: any*/)
                                            ],
                                            "type": "JiraRadioSelectField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v27/*: any*/),
                                              {
                                                "args": (v31/*: any*/),
                                                "concreteType": "JiraVersionConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedVersionsConnection",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraVersionEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraVersion",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v14/*: any*/),
                                                          (v27/*: any*/),
                                                          (v32/*: any*/),
                                                          (v33/*: any*/),
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "versionId"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "storageKey": "selectedVersionsConnection(first:1000)"
                                              },
                                              (v30/*: any*/)
                                            ],
                                            "type": "JiraMultipleVersionPickerField"
                                          }
                                        ]
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraCmdbObjectConnection",
                                            "kind": "LinkedField",
                                            "name": "selectedCmdbObjectsConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraCmdbObjectEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraCmdbObject",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "objectId"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "label"
                                                      },
                                                      {
                                                        "concreteType": "JiraCmdbAvatar",
                                                        "kind": "LinkedField",
                                                        "name": "avatar",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraCmdbMediaClientConfig",
                                                            "kind": "LinkedField",
                                                            "name": "mediaClientConfig",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "clientId"
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "fileId"
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "mediaBaseUrl"
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "mediaJwtToken"
                                                              }
                                                            ]
                                                          },
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "url48"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              },
                                              {
                                                "concreteType": "QueryError",
                                                "kind": "LinkedField",
                                                "name": "errors",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "message"
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ],
                                        "type": "JiraCMDBField"
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": false,
                                        "selections": [
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "alias": "componentsOld",
                                                "concreteType": "JiraComponentConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedComponentsConnection",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraComponentEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraComponent",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": (v44/*: any*/)
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ],
                                            "type": "JiraComponentsField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v35/*: any*/)
                                            ],
                                            "type": "JiraSingleLineTextField"
                                          }
                                        ]
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "date"
                                          },
                                          (v27/*: any*/),
                                          (v45/*: any*/),
                                          (v30/*: any*/)
                                        ],
                                        "type": "JiraDatePickerField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v34/*: any*/)
                                        ],
                                        "type": "JiraDateTimePickerField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraEpic",
                                            "kind": "LinkedField",
                                            "name": "epic",
                                            "plural": false,
                                            "selections": [
                                              (v12/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "summary"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "color"
                                              },
                                              (v14/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraEpicLinkField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "renderedFieldHtml"
                                          }
                                        ],
                                        "type": "JiraFallbackField"
                                      },
                                      (v36/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraStatusCategory",
                                            "kind": "LinkedField",
                                            "name": "statusCategory",
                                            "plural": false,
                                            "selections": [
                                              (v27/*: any*/),
                                              (v46/*: any*/),
                                              (v14/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraStatusCategoryField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraStatus",
                                            "kind": "LinkedField",
                                            "name": "status",
                                            "plural": false,
                                            "selections": [
                                              (v27/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "statusId"
                                              },
                                              (v47/*: any*/),
                                              (v14/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraStatusField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "args": (v31/*: any*/),
                                            "concreteType": "JiraLabelConnection",
                                            "kind": "LinkedField",
                                            "name": "selectedLabelsConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraLabelEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraLabel",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v27/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              },
                                              (v37/*: any*/)
                                            ],
                                            "storageKey": "selectedLabelsConnection(first:1000)"
                                          },
                                          (v27/*: any*/),
                                          (v30/*: any*/)
                                        ],
                                        "type": "JiraLabelsField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueType",
                                            "kind": "LinkedField",
                                            "name": "issueType",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraAvatar",
                                                "kind": "LinkedField",
                                                "name": "avatar",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "small"
                                                  }
                                                ]
                                              },
                                              (v27/*: any*/),
                                              (v14/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraIssueTypeField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssue",
                                            "kind": "LinkedField",
                                            "name": "parentIssue",
                                            "plural": false,
                                            "selections": [
                                              (v12/*: any*/),
                                              (v48/*: any*/),
                                              (v14/*: any*/)
                                            ]
                                          },
                                          {
                                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                            "kind": "Condition",
                                            "passingValue": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "parentIssue",
                                                "plural": false,
                                                "selections": [
                                                  (v12/*: any*/),
                                                  (v49/*: any*/),
                                                  (v51/*: any*/),
                                                  (v48/*: any*/),
                                                  (v14/*: any*/)
                                                ]
                                              }
                                            ]
                                          },
                                          {
                                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                            "kind": "Condition",
                                            "passingValue": true,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "parentIssue",
                                                "plural": false,
                                                "selections": [
                                                  (v12/*: any*/),
                                                  (v49/*: any*/),
                                                  (v51/*: any*/),
                                                  (v48/*: any*/),
                                                  (v14/*: any*/),
                                                  (v11/*: any*/),
                                                  (v52/*: any*/),
                                                  {
                                                    "args": [
                                                      {
                                                        "kind": "Literal",
                                                        "name": "ids",
                                                        "value": [
                                                          "summary",
                                                          "issuetype",
                                                          "status"
                                                        ]
                                                      }
                                                    ],
                                                    "concreteType": "JiraIssueFieldConnection",
                                                    "kind": "LinkedField",
                                                    "name": "fieldsById",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraIssueFieldEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              (v25/*: any*/),
                                                              {
                                                                "kind": "InlineFragment",
                                                                "selections": [
                                                                  (v26/*: any*/),
                                                                  (v35/*: any*/)
                                                                ],
                                                                "type": "JiraSingleLineTextField"
                                                              },
                                                              {
                                                                "kind": "InlineFragment",
                                                                "selections": [
                                                                  (v26/*: any*/),
                                                                  {
                                                                    "concreteType": "JiraIssueType",
                                                                    "kind": "LinkedField",
                                                                    "name": "issueType",
                                                                    "plural": false,
                                                                    "selections": [
                                                                      {
                                                                        "kind": "ScalarField",
                                                                        "name": "issueTypeId"
                                                                      },
                                                                      (v27/*: any*/),
                                                                      (v50/*: any*/),
                                                                      (v15/*: any*/),
                                                                      (v14/*: any*/)
                                                                    ]
                                                                  }
                                                                ],
                                                                "type": "JiraIssueTypeField"
                                                              },
                                                              {
                                                                "kind": "InlineFragment",
                                                                "selections": [
                                                                  (v26/*: any*/),
                                                                  {
                                                                    "concreteType": "JiraStatus",
                                                                    "kind": "LinkedField",
                                                                    "name": "status",
                                                                    "plural": false,
                                                                    "selections": [
                                                                      (v47/*: any*/),
                                                                      (v14/*: any*/)
                                                                    ]
                                                                  }
                                                                ],
                                                                "type": "JiraStatusField"
                                                              },
                                                              (v14/*: any*/)
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ],
                                                    "storageKey": "fieldsById(ids:[\"summary\",\"issuetype\",\"status\"])"
                                                  }
                                                ]
                                              },
                                              (v27/*: any*/),
                                              (v30/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraParentIssueField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraPriority",
                                            "kind": "LinkedField",
                                            "name": "priority",
                                            "plural": false,
                                            "selections": [
                                              (v27/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "iconUrl"
                                              },
                                              (v14/*: any*/)
                                            ]
                                          },
                                          (v27/*: any*/),
                                          (v30/*: any*/)
                                        ],
                                        "type": "JiraPriorityField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraProject",
                                            "kind": "LinkedField",
                                            "name": "project",
                                            "plural": false,
                                            "selections": [
                                              (v27/*: any*/),
                                              (v12/*: any*/),
                                              {
                                                "concreteType": "JiraAvatar",
                                                "kind": "LinkedField",
                                                "name": "avatar",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "large"
                                                  }
                                                ]
                                              },
                                              (v14/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraProjectField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraResolution",
                                            "kind": "LinkedField",
                                            "name": "resolution",
                                            "plural": false,
                                            "selections": (v44/*: any*/)
                                          }
                                        ],
                                        "type": "JiraResolutionField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraSprintConnection",
                                            "kind": "LinkedField",
                                            "name": "selectedSprintsConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraSprintEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraSprint",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v14/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "sprintId"
                                                      },
                                                      (v27/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "state"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "endDate"
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          },
                                          (v27/*: any*/),
                                          (v30/*: any*/)
                                        ],
                                        "type": "JiraSprintField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                              (v25/*: any*/),
                                              (v41/*: any*/),
                                              (v42/*: any*/),
                                              (v27/*: any*/),
                                              (v14/*: any*/),
                                              (v43/*: any*/)
                                            ]
                                          },
                                          (v27/*: any*/),
                                          (v45/*: any*/),
                                          (v30/*: any*/)
                                        ],
                                        "type": "JiraSingleSelectUserPickerField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v27/*: any*/),
                                          {
                                            "args": (v31/*: any*/),
                                            "concreteType": "JiraVersionConnection",
                                            "kind": "LinkedField",
                                            "name": "selectedVersionsConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraVersionEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraVersion",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": (v44/*: any*/)
                                                  }
                                                ]
                                              }
                                            ],
                                            "storageKey": "selectedVersionsConnection(first:1000)"
                                          }
                                        ],
                                        "type": "JiraMultipleVersionPickerField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraRichText",
                                            "kind": "LinkedField",
                                            "name": "richText",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraADF",
                                                "kind": "LinkedField",
                                                "name": "adfValue",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "condition": "isDensityFull",
                                                    "kind": "Condition",
                                                    "passingValue": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraAdfToConvertedPlainText",
                                                        "kind": "LinkedField",
                                                        "name": "convertedPlainText",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "plainText"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  },
                                                  {
                                                    "condition": "isDensityFull",
                                                    "kind": "Condition",
                                                    "passingValue": true,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "json"
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ],
                                        "type": "JiraRichTextField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraServiceManagementRequestType",
                                            "kind": "LinkedField",
                                            "name": "requestType",
                                            "plural": false,
                                            "selections": [
                                              (v27/*: any*/),
                                              (v50/*: any*/),
                                              (v14/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraServiceManagementRequestTypeField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "args": [
                                              {
                                                "kind": "Literal",
                                                "name": "first",
                                                "value": 1
                                              }
                                            ],
                                            "concreteType": "JiraIssueLinkConnection",
                                            "kind": "LinkedField",
                                            "name": "issueLinkConnection",
                                            "plural": false,
                                            "selections": [
                                              (v37/*: any*/),
                                              {
                                                "concreteType": "JiraIssueLinkEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraIssueLink",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraIssue",
                                                        "kind": "LinkedField",
                                                        "name": "issue",
                                                        "plural": false,
                                                        "selections": [
                                                          (v52/*: any*/),
                                                          (v12/*: any*/),
                                                          {
                                                            "args": [
                                                              {
                                                                "kind": "Literal",
                                                                "name": "ids",
                                                                "value": [
                                                                  "statusCategory"
                                                                ]
                                                              }
                                                            ],
                                                            "concreteType": "JiraIssueFieldConnection",
                                                            "kind": "LinkedField",
                                                            "name": "fieldsById",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "concreteType": "JiraIssueFieldEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                  {
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                      (v25/*: any*/),
                                                                      {
                                                                        "kind": "InlineFragment",
                                                                        "selections": [
                                                                          (v47/*: any*/)
                                                                        ],
                                                                        "type": "JiraStatusCategoryField"
                                                                      },
                                                                      (v14/*: any*/)
                                                                    ]
                                                                  }
                                                                ]
                                                              }
                                                            ],
                                                            "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                                                          },
                                                          (v14/*: any*/)
                                                        ]
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "relationName"
                                                      },
                                                      (v14/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ],
                                            "storageKey": "issueLinkConnection(first:1)"
                                          }
                                        ],
                                        "type": "JiraIssueLinkField"
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "isStoryPointField"
                                              },
                                              (v53/*: any*/),
                                              (v27/*: any*/),
                                              {
                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                                "kind": "Condition",
                                                "passingValue": false,
                                                "selections": [
                                                  (v53/*: any*/)
                                                ]
                                              },
                                              {
                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                                "kind": "Condition",
                                                "passingValue": true,
                                                "selections": [
                                                  (v53/*: any*/),
                                                  (v27/*: any*/),
                                                  (v30/*: any*/)
                                                ]
                                              },
                                              (v30/*: any*/)
                                            ],
                                            "type": "JiraNumberField"
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v13/*: any*/)
                ]
              },
              {
                "args": (v18/*: any*/),
                "concreteType": "JiraIssueSearchViewContexts",
                "kind": "LinkedField",
                "name": "searchViewContext",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "contexts",
                    "plural": true,
                    "selections": [
                      (v25/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/)
                    ]
                  }
                ]
              },
              (v14/*: any*/),
              {
                "condition": "isHierarchyEnabled",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueTypeField",
                    "kind": "LinkedField",
                    "name": "issueTypeField",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      {
                        "concreteType": "JiraIssueType",
                        "kind": "LinkedField",
                        "name": "issueType",
                        "plural": false,
                        "selections": [
                          (v15/*: any*/),
                          (v14/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v16/*: any*/)
                ]
              },
              (v13/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "43e25a80d5826b6301709f35dd671e91bc63841e44f07ac54b8137ae0610d4b5",
    "metadata": {},
    "name": "realtimeIssueDetails_Query",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
    }
  }
};
})();

(node as any).hash = "8ecece052ab722377302c82e934540c2";

export default node;
